import * as React from 'react'
import { Container, createStyles, makeStyles, Theme, Typography, Grid } from '@material-ui/core'
import { Link } from 'gatsby'
import IndexLayout from '../templates/IndexLayout'
import OperatorRegisterInterestForm from '../components/OperatorRegisterInterestForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: '2rem'
    },
    subHeader: {
      fontSize: '1.2rem'
    }
  })
)

export default function OperatorRegisterInterest() {
  const classes = useStyles()
  return (
    <IndexLayout title="Fleet Contact Us" description="Contact us to find out more about electrifying your fleet with our patent-pending finance">
      <div style={{ height: '10vh' }} />
      <Container >
        <Grid container justifyContent="center" spacing={5}>
          <Grid item sm={6}>
            <Typography className={classes.header} variant="h6">
              Register your interest in financing a fleet of clean vehicles
            </Typography>
            <Typography className={classes.subHeader} variant="body1">
              If you’re a fleet operator interested in maximising your cashflow with our patent-pending pay-per-mile financing, as well as
              helping improve the air quality of your community, fill out our form here.
              <br />
              <br /> Not sure of all of the answers? Just send in what you can, and our team will help you with the rest - or use our
              general-purpose contact us form <Link to="/contact-us/">here</Link>.
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <OperatorRegisterInterestForm />
          </Grid>
        </Grid>
      </Container>
    </IndexLayout>
  )
}
